import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../Sportsbook/sportsbook.css";
import "./pubrace_host.css";
import { updateLocation } from "../../../redux/App/actions";
import { setRacerLogin } from "../../../redux/Account/actions";
import {axiosInstance, closeAllModals} from "../../../utils";
import {PubRaceInfo, PubRaceLeaderboard, PubRaceController, PubRaceInfoParty, PubRaceControllerParty} from "./Sections";
import {
    ParityOffsiteHost, 
    PolkadotThailandHost, 
    ApillonHost, 
    PuntClubHost, 
    BashPartyHost, 
    OakNetworkHost, 
    BashConsensusPartyHost,
    BashMainnetPartyHost,
    PinkHost} from "./Event";
import $ from 'jquery';

let web3;
let racer_sim_watched_global = 0;
let race_simulated_global = 0;
let current_racer_global = 0;
let racer_invite_status_global = 0;
let pubrace_event_id = '';
let current_race_id = '';
let current_race_hash = '';
let current_race_group = 6;
let current_collection_id = 2;
let open_invite_race = 0;
let open_entry_closed = 0;
let predictions_closed = 0;
let entryDisplay = 'none';
let training_box_race_landing = 0;
let current_time_schedule = 0;
let scheduleready = 0;
let schedulestarted = 0;
let race_schedule_array = [];
let current_sort = 'recent';
let current_filter = 'groupid_6';
let watchfilter = '';
let current_group_array = [];
let pickem_admin = [1,3,5,55,56,61];
let currentnewrace = 0;
let newracecreatetime;

export const PubRaceHost = () => {
    const dispatch = useDispatch();
    const { racerID, userID, accountAddress } = useSelector((state) => state.account);
    const { admin_racers } = useSelector((state) => state.app);

    var url_race_unique_id = '';
    var url_event_id = '';
    var start_race_section = '';
    var tmp_left_nav_section = 'upcoming';
    var racehash = window.location.href.split("#/");
    if(racehash.length > 1) {
        var racehash2 = racehash[1].replace("#/",'');
        var racehash3 = racehash2.split("/");
        if(racehash3.length > 1) {
            url_event_id = racehash3[1].replace("/",'');
        }
    }
    pubrace_event_id = url_event_id;

    const [pageSet, setPage] = useState(false);
    const [raceHash, setRaceHash] = useState('');
    const [pubRaceHost, setPubRaceHost] = useState('');
    const [pubRaceInfoHTML, setPubRaceInfoHTML] = useState('');
    const [pubRaceControllerHTML, setPubRaceControllerHTML] = useState('');
    const [pubRaceControllerHTML2, setPubRaceControllerHTML2] = useState('');
    
    const [pubRaceLeaderboardHTML, setPubRaceLeaderboadHTML] = useState('');

    var tmpspinner = `<div class="preloader-wrapper small active" style="margin-top:15px">
                                <div class="spinner-layer spinner-red-only">
                                    <div class="circle-clipper left">
                                        <div class="circle"></div>
                                    </div>
                                    <div class="gap-patch">
                                        <div class="circle"></div>
                                    </div>
                                    <div class="circle-clipper right">
                                        <div class="circle"></div>
                                    </div>
                                </div>
                            </div>`;
    
    var racer_items = [];
    function grabRacerRaces(pagenum) {
        if(pubrace_event_id == 'bashmainnet') {
            current_filter = 'groupid_22';
            current_race_group = 22;
            current_collection_id = 15;
            console.log(current_race_group);
            watchfilter='';
            watchfilter='&event=bashmainnet';
            setPubRaceHost(<BashMainnetPartyHost />);
        } else if(pubrace_event_id == 'bashconsensus') {
            current_filter = 'groupid_21';
            current_race_group = 21;
            current_collection_id = 14;
            console.log(current_race_group);
            watchfilter='';
            watchfilter='&event=bashconsensus';
            setPubRaceHost(<BashConsensusPartyHost />);
        } else if(pubrace_event_id == 'oaknetwork') {
            current_filter = 'groupid_20';
            current_race_group = 20;
            current_collection_id = 13;
            watchfilter='';
            watchfilter='&event=oaknetwork';
            setPubRaceHost(<OakNetworkHost />);
        }  else if(pubrace_event_id == 'pinkparty') {
            current_filter = 'groupid_19';
            current_race_group = 19;
            current_collection_id = 12;
            watchfilter='';
            watchfilter='&event=pinkparty';
            setPubRaceHost(<BashPartyHost />);
        } else if(pubrace_event_id == 'bashdenver') {
            current_filter = 'groupid_19';
            current_race_group = 19;
            current_collection_id = 12;
            watchfilter='';
            watchfilter='&event=bashdenver';
            setPubRaceHost(<BashPartyHost />);
        } else if(pubrace_event_id == 'puntclub') {
            current_filter = 'groupid_18';
            current_race_group = 18;
            current_collection_id = 11;
            watchfilter='';
            watchfilter='&event=puntclub';
            setPubRaceHost(<PuntClubHost />);
        } else if(pubrace_event_id == 'apillon') {
            current_filter = 'groupid_17';
            current_race_group = 17;
            current_collection_id = 10;
            watchfilter='';
            watchfilter='&event=apillon';
            setPubRaceHost(<ApillonHost />);
        } else if(pubrace_event_id == 'polkadot-thailand') {
            current_filter = 'groupid_16';
            current_race_group = 16;
            current_collection_id = 9;
            watchfilter='&event=polkadot-thailand';
            setPubRaceHost(<PolkadotThailandHost />)
        } else if(pubrace_event_id == 'parity') {
            current_filter = 'groupid_15';
            current_race_group = 15;
            current_collection_id = 8;
            watchfilter='&event=parity';
            setPubRaceHost(<ParityOffsiteHost />)
        } else {
            current_filter = 'groupid_6';
            current_race_group = 6;
            current_collection_id = 2;
            watchfilter='';
            setPubRaceHost(<ParityOffsiteHost />)
        }
        var addData = new Object();
        addData.userID = encodeURIComponent(userID);
        addData.sortBy = encodeURIComponent(current_sort);
        addData.filterBy = encodeURIComponent(current_filter);
        // addData.raceStatus = 'past';
        addData.raceStatus = 'live_pickem';
        addData.page = pagenum;
        addData.rcount = 20;
        addData.raceHost = encodeURIComponent(window.location.host);

        axiosInstance.post('sportsbook/recent_races/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var racer_details = result.data;
                var next_race_hash = racer_details[0]['race_unique_id'];
                var next_race_status = 0;
                if(racer_details[0]['date_race_ended'] != null) {
                    next_race_status = 1;
                }
                updatePubRaceInfo(next_race_hash,next_race_status);
                if(next_race_hash.length > 0) {
                    current_race_id = racer_details[0]['race_id'];
                }
                
            } else { 
                setPubRaceInfoHTML(<PubRaceInfo raceID={'waitroom'} />);
                setPubRaceControllerHTML(<PubRaceController newRaceButton={createNewRace} simRaceButton={simulateLastRace} raceID={raceHash} raceGroupID={current_race_group}  raceCollectionID={current_collection_id} />)
                setPubRaceLeaderboadHTML(<PubRaceLeaderboard raceID={raceHash} raceGroupID={current_race_group}  raceCollectionID={current_collection_id} />);
                
            }
        }).catch(error => {
            console.log(error);
            setPubRaceInfoHTML(<PubRaceInfo raceID={'waitroom'} />)
        });
    }

    function updatePubRaceInfo(newRaceHash,newRaceStatus) {
        if(newRaceHash.length > 0) {
            current_race_hash = newRaceHash;
            setRaceHash(newRaceHash);
            if(((current_race_group == 19)||(current_race_group == 21)||(current_race_group == 22))&&(newRaceStatus == 0)) {
                setPubRaceInfoHTML(<PubRaceInfoParty raceID={newRaceHash} raceGroupID={current_race_group} />);
                setPubRaceControllerHTML2(<PubRaceControllerParty newRaceButton={createNewRace} simRaceButton={simulateLastRaceInline} raceID={newRaceHash} raceGroupID={current_race_group}  raceCollectionID={current_collection_id} />)
                $("#results-controller").hide();
                $("#upcoming-controller").show();
            } else {
                setPubRaceInfoHTML(<PubRaceInfo raceID={newRaceHash} raceGroupID={current_race_group} />);
                setPubRaceControllerHTML(<PubRaceController newRaceButton={createNewRace} simRaceButton={simulateLastRace} raceID={newRaceHash} raceGroupID={current_race_group}  raceCollectionID={current_collection_id} />)
                if((current_race_group == 19)||(current_race_group == 21)||(current_race_group == 22)) {
                    startCreateNewRaceTimer();
                }
                $("#results-controller").show();
                $("#upcoming-controller").hide();
            }
            
            
            setPubRaceLeaderboadHTML(<PubRaceLeaderboard raceID={newRaceHash} raceGroupID={current_race_group} raceCollectionID={current_collection_id} />)
        }
    }

    function startCreateNewRaceTimer() {
        var now = new Date();
        var diff = 2;
        newracecreatetime = new Date(now.getTime() + diff*60000);
        loadNewRaceTimer();
    }

    function loadNewRaceTimer() {
        
        var countDownDate = newracecreatetime;

        // Update the count down every 1 second
        // Get today's date and time
        var now = Date.now();
        // Find the distance between now and the count down date
        var distance = (countDownDate - now);
        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        var minutes = Math.floor(
            (distance % (1000 * 60 * 60)) / (1000 * 60)
        );
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        var finaldays = "";
        var finalhours = "";
        var finalminutes = "";
        var finalseconds = "";
        if (days == 0) {
            finaldays = "";
        } else {
            finaldays = days + "d ";
        }
        if ((hours == 0)&&(finaldays == 0)) {
            finalhours = "";
        } else if (hours < 10) {
            finalhours = "0" + hours + "h ";
        } else {
            finalhours = hours + "h ";
        }
        if ((minutes == 0)&&(finalhours == 0)) {
            finalminutes = "";
        } else if (minutes < 10) {
            finalminutes = "0" + minutes + "m ";
        } else {
            finalminutes = minutes + "m ";
        }
        if (seconds < 10) {
            finalseconds = "0" + seconds + "s ";
        } else {
            finalseconds = seconds + "s ";
        }

        // Display the result in the element with id="demo"
        
        // If the count down is finished, write some text
        // if((action == 'play')&&(race_details_global[0]['results_viewable'] == 0)) {
        if(distance <= 0) {
            if(currentnewrace == 0) {
                $("#countdown-timer-pred").html('...');
                currentnewrace = 1;
                createNewRaceInline();
            }
        } else {
            $("#countdown-timer-pred").html(finaldays + finalhours + finalminutes + finalseconds);
            setTimeout(function() {
                loadNewRaceTimer();
            },1000);
        }
    }
    


    function simulateLastRace() {
        var addData = new Object();
        addData.raceUniqueID = encodeURIComponent(current_race_hash);
        addData.collectionID = encodeURIComponent(current_collection_id);
        if(window.confirm('Are You Sure You Want To Simulate This Race?')) {
            $("#simulate-new-race-button").html(tmpspinner);
            axiosInstance.post('race/simulate_race/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    var sim_details = result.data;
                    setTimeout(function() {
                        // watchInitialRace();
                        window.location.href= '/#/watchlive/'+current_race_hash+watchfilter;
                    },2000);
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    function simulateLastRaceInline() {
        var addData = new Object();
        addData.raceUniqueID = encodeURIComponent(current_race_hash);
        addData.collectionID = encodeURIComponent(current_collection_id);
        $("#simulate-new-race-button").html(tmpspinner);
        axiosInstance.post('race/simulate_race/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var sim_details = result.data;
                setTimeout(function() {
                    // watchInitialRace();
                    window.location.href= '/#/watchlive/'+current_race_hash+watchfilter;
                },2000);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function createNewRace() {
        var addData = new Object();
        addData.raceGroupID = encodeURIComponent(current_race_group);
        if(window.confirm('Are You Sure You Want To Create a New Race?')) {
            $("#start-new-race-button").html(tmpspinner);
            axiosInstance.post('sportsbook/new_pickem_live/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    var sim_details = result.data;
                    setTimeout(function() {
                        window.location.reload();
                    },2000);
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    function createNewRaceInline() {
        var addData = new Object();
        addData.raceGroupID = encodeURIComponent(current_race_group);
        $("#start-new-race-button").html(tmpspinner);
        axiosInstance.post('sportsbook/new_pickem_live/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var sim_details = result.data;
                setTimeout(function() {
                    window.location.reload();
                },2000);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function watchInitialRace() {
        var addData = new Object();
        addData.raceID = encodeURIComponent(current_race_id);
        addData.racerID = encodeURIComponent(racerID);
        axiosInstance.post('race/racer_watched/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                window.location.href= '/#/watchlive/'+current_race_hash+watchfilter;
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function markRaceResultsOpen(traceid) {
        var addData = new Object();
        addData.raceID = encodeURIComponent(traceid);
        axiosInstance.post('race/results_open/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                console.log('Race Results Open To View');
            }
        }).catch(error => {
            console.log(error);
        });
    }

    if(pageSet == false) {
        setPage(true);
        window.startPageModals();
        window.resetBodyWidth();
        grabRacerRaces(0);
    }

    return(<div style={{position:'relative',width:'auto',height:'auto'}}>
            {pubRaceHost}
            <div className="row white-text page-width-wide top-nav-header-full">
                <div className="col s12 m7 l9" style={{height:'85vh',display:'flex',flexDirection:'column',overflow:'hidden',overflowY:'scroll'}}>
                    <div id="upcoming-controller" className="row" style={{marginBottom:'10px'}}>
                        {pubRaceControllerHTML2}
                    </div>
                    <div className="row" style={{flex:'1',marginBottom:'10px'}}>
                        {pubRaceInfoHTML}
                    </div>
                    <div id="results-controller" className="row" style={{marginBottom:'0px'}}>
                        {pubRaceControllerHTML}
                    </div>
                </div>
                <div className="col s12 m5 l3" style={{height:'85vh'}}>
                    {pubRaceLeaderboardHTML}
                </div>
            </div>
        </div>);
};
